/* global newrelic: false */

function TrackNewrelic({ newrelicTransactionName, newrelicNoticeError }) {
  if (typeof newrelic === 'function' && newrelicTransactionName) {
    newrelic.setPageViewName(newrelicTransactionName);
  } else if (typeof newrelic === 'function' && newrelicNoticeError) {
    newrelic.noticeError(new Error(), newrelicNoticeError);
  }

  return null;
}

export default TrackNewrelic;
