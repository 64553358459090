/* istanbul ignore file */

import React from 'react';
import { bool, object, oneOf, string, node, array, shape, number, func } from 'prop-types';
import classNames from 'classnames';
import { MelidataTrack } from 'nordic/melidata/melidata-track';
import { Script } from 'nordic/script';
import { Style } from 'nordic/style';
import { loadable, LazyHydrate as Hydrator } from 'nordic/lazy';
import { Head } from 'nordic/head';
import { injectI18n } from 'nordic/i18n';
import { AndesProvider } from '@andes/context';

import Hotjar from './hotjar';
import Schema from '../../commons/schema';
import MeliMessages from './meli-messages';
import Header from '../../commons/header';
import TrackNewrelic from '../../commons/track-newrelic';
import ComponentList from '../../commons/component-list/mobile';
import { getCriticalCHScript } from '../../../../utils/critical-ch';
import { SVG_SPRITES } from '../../../../utils/constants';

const RedirectApp = loadable(() => import('redirect-app-web'));
const Interstitial = loadable(() => import('./loyalty-interstitial'));

function Home({
  atf,
  csrfToken,
  deviceType,
  lowEnd = true,
  layout,
  restClient,
  siteId = null,
  canonical,
  hotjar = null,
  mainDomain,
  children = null,
  newrelicTransactionName,
  trackExperiments = null,
  ssrComponents = null,
  isServerSide = null,
  preloadRecommendationsImgs = null,
  currentUser = null,
  melidataEventData = null,
  melidataPath = '/home',
  i18n,
  userConsentCookie,
  criticalCH = null,
  openInApp = null,
  country,
  nonce,
  viewportWidth,
  serializeProps,
}) {
  const forwardProps = {
    lowEnd,
    restClient,
    deviceType,
    layout,
    siteId,
    canonical,
    metadata: atf.metadata,
    bookmarks: atf.bookmarks,
    isServerSide,
    currentUser,
    userConsentCookie,
    country,
    viewportWidth,
    nonce,
  };

  const list = ssrComponents || atf.components;
  const title = i18n.gettext('Mercado Libre');
  const isPolycard = list.some((reco) => reco.is_polycard);

  return (
    <div className={classNames('home', { 'low-end': lowEnd })}>
      {openInApp && openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <Head>
        <Schema schemaData={atf.schemadata} />
      </Head>
      <MelidataTrack path={melidataPath} type="view" experiments={trackExperiments} event_data={melidataEventData} />
      <Header
        {...atf.header}
        canonical={canonical}
        siteId={siteId}
        preloadRecommendationsImgs={preloadRecommendationsImgs}
      />
      <Style href={`home.${deviceType}.css`} critical={deviceType === 'mobile'} />
      <Style
        href={
          isPolycard
            ? 'polycard-web-lib.css'
            : `recommendations-fe-${deviceType}.css`
        }
        critical={deviceType === 'mobile'}
      />
      {(criticalCH && criticalCH.allowedParticipant) && <Script on="now">{getCriticalCHScript(criticalCH)}</Script>}
      <Script priority={1}>{`window.ML_PRELOADED_STATE = ${serializeProps};`}</Script>
      <h1 className="clipped">{title}</h1>
      {atf.interstitial && (
        <Interstitial
          deviceType={deviceType}
          {...atf.interstitial}
          restClient={restClient}
          userId={currentUser.id ?? null}
          siteId={siteId}
        />
      )}
      <AndesProvider
        locale={country?.locale?.replace('_', '-')}
        csrfToken={csrfToken}
      >
        <ComponentList
          list={list}
          {...forwardProps}
        />
      </AndesProvider>
      <Hotjar {...hotjar} deviceType={deviceType} nonce={nonce} />
      <MeliMessages siteId={siteId} mainDomain={mainDomain} />
      {children}

      <TrackNewrelic newrelicTransactionName={newrelicTransactionName} />
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
        {`
        window.viewability.setNonceForCSP('${nonce}')
        `}
      </Script>
      <Hydrator ssrOnly>{SVG_SPRITES}</Hydrator>
      <noscript />
    </div>
  );
}

Home.propTypes = {
  isLab: bool.isRequired,
  cleanPwaServiceWorkers: bool.isRequired,
  isFirstVisit: bool.isRequired,
  atf: object.isRequired,
  csrfToken: string.isRequired,
  deviceType: oneOf(['desktop', 'mobile', 'tablet']).isRequired,
  layout: oneOf(['desktop', 'mobile']).isRequired,
  lowEnd: bool,
  restClient: object.isRequired,
  siteId: string,
  canonical: string.isRequired,
  translations: object.isRequired,
  hotjar: object,
  mainDomain: string.isRequired,
  children: node,
  newrelicTransactionName: string.isRequired,
  trackExperiments: object,
  ssrComponents: array,
  isServerSide: bool,
  preloadRecommendationsImgs: array,
  currentUser: shape({
    id: number.isRequired,
    nickname: string.isRequired,
  }),
  melidataEventData: object,
  melidataPath: string,
  i18n: shape({
    gettext: func,
  }).isRequired,
  userConsentCookie: bool.isRequired,
  criticalCH: object,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  imagesPrefix: string,
  country: shape({
    locale: string,
  }),
  nonce: string,
  viewportWidth: string,
  serializeProps: string,
};

export default injectI18n(Home);

